/**
 * В данный файл вносятся ассеты из одноименного файла в фигме.
 * https://www.figma.com/file/rJZQqfAYSxZk07CnajqPJo/Assets?node-id=33%3A2
 */

const colors = {
  brand: {
    primary: '#2B2AEC',
    secondary: '#FF5F19',
    white: '#FFFFFF',
  },
  text: {
    primary: '#000000',
    secondary: '#4B5563',
    disabled: '#C2C2C2',
    link: '#1400DC',
    linkVisited: '#6A4FF6',
  },
  icon: {
    primary: '#000000',
    secondary: '#AFAFAF',
    active: '#1400DC',
    disabled: '#DADBDD',
  },
  accent: {
    success: '#00AC5F',
    successBackground: '#E9FAEF',
    error: '#EB2A38',
    errorBackground: '#FFEBEF',
    warning: '#FC9403',
    warningBackground: '#FFF3DC',
    active: '#3081FA',
    activeBackground: '#EBF5FF',
    cancel: '#AFAFAF',
    cancelBackground: '#F3F3F5',
  },
  buttons: {
    active: '#1400DC',
    hover: '#2B2AEC',
    disabled: '#D7D7D7',
  },
  background: {
    primary: '#FCFCFC',
    secondary: '#F5F5F5',
    secondaryHover: '#EBEBEB',
    secondaryActive: '#DDDDDD',
  },
  divider: {
    primary: '#EFEFEF',
  },
  border: {
    disabled: '#F9F9F9',
    active: '#EBEBEB',
    hover: '#DADBDD',
    focus: '#B9B9BB',
    primary: '#EFEFEF',
  },
  opacity: {
    background: 0.1,
  },
};

export default colors;
